import React from "react";
import Layout from "../../components/Layout";
import Topbar from "../../components/Topbar";
import NavOne from "../../components/NavOne";
import SliderOne from "../../components/SliderOne";
import SliderTwo from "../../components/SliderTwo";
import Footer from "../../components/Footer";
import AboutTwo from "../../components/AboutTwo";
import CourseOne from "../../components/CourseOne";
import VideoTwo from "../../components/VideoTwo";
import CountdownKipso from "../../components/CountdownKipso";
import CourseCatOne from "../../components/CourseCatOne";
import CallToActionThree from "../../components/CallToActionThree";
import BrandsTwo from "../../components/BrandsTwo";
import BlogTwo from "../../components/BlogTwo";
import CallToActionFour from "../../components/CallToActionFour";
import SubscribeOne from "../../components/SubscribeOne";

const HomePage = () => (
  <Layout pageTitle="Lagos University Teaching Hospital LUTH">
    {/* <Topbar /> */}
    <NavOne />
    <SliderTwo />
    <AboutTwo />
    {/* <CourseOne />
    <VideoTwo />
    <CountdownKipso />
    <CourseCatOne />
    <CallToActionThree />
    <BrandsTwo /> */}
    <BlogTwo />
    {/* <CallToActionFour /> */}
    {/* <SubscribeOne /> */}
    <Footer />
  </Layout>
);

export default HomePage;
